body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-section {
  background-image: url("../public/background.avif");
  background-position: center;
  background-size: cover;
  height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
}

.top-section h1 {
  font-size: 48px;
}
.top-section p {
  font-size: 20px;
}

.navbar {
  background-color: #f5f5f5;
}

.navbar-brand img {
  margin-right: 12px;
}

.navbar-brand .brand-name {
  text-align: center;
  line-height: 1;
  font-size: medium;
}

.section {
  margin-top: 80px;
}

.section .section-title {
  color: #388e3c;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 9px;
  margin-bottom: 28px;
}

.section-separator {
  background: url("/public/section-separator.jpg");
  background-size: cover;
  height: 175px;
  margin-top: 65px;
}

.btn-primary {
  background-color: #ffc107;
  border-color: #EFB916;
}
.btn-primary:hover, .btn-primary:active{
  background-color: #EFB916 !important;
  border-color: #EFB916 !important;
}

.bg-light-primary {
  background-color: #e6f1e7;
}

.read-more-btn {
  color: #4caf50;
  border: 1px solid #4caf50;
}

.read-more-btn:hover {
  background-color: #4caf50;
  color: white;
  border-color: #cae6c9;
}

/* .accordion-button {
  background-colo;
} */

.accordion-button:not(.collapsed) {
  background-color: #c8e6c9;
}

.accordion-button:focus {
 box-shadow: 0 0 0 0.25rem #c8e6c9;
}

.foot {
  background-color: #c8e6c9;
  padding-bottom: 24px;
  padding-top: 24px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #4caf50;
}

.contact-section {
  padding: 36px;
  background-color: #EAF6EB;
}

/* offset anchors to account for fixed header */
.section::before {
  content: '';
  display: block;
  height:      120px;
  margin-top: -120px;
  visibility: hidden;
}